import moment from 'moment';
import './mousewheel.js'
import './jscrollpane.js'
import './insta.js'
import 'magnific-popup';
jQuery( document ).ready(function($) {
    // Tour
    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/David%20Leonard/events?app_id=45PRESS_david-leonard',
        method: 'GET',
        dataType: 'json',
        xhrFields: {
            withCredentials: false
        },
        error: () => { 
        },
        success: data => {
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                    html += '<div class="event" data-bit-id="' + event.id + '">';
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
                    html += '<div class="event-location">' + event.venue.city + ', ' + event.venue.region + '</div>';
                    html += '<div class="event-venue">' + event.venue.name + '</div>';
                    html += '<a href="' + event.url + '" target="_blank" class="btn btn-white">Tickets</a>';
                    html += '</div>';
                }
                events.html( html );
            } else {
                events.html( 'No upcoming events.' );
            }
        }
    });

    // Bio scroll
    $('.bio-wrapper').jScrollPane();

    // music slider
    $('.music-slider').slick({
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>'
    });

    // SHOP

    

    // (function () {
    //     var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    //     if (window.ShopifyBuy) {
    //       if (window.ShopifyBuy.UI) {
    //         ShopifyBuyInit();
    //       } else {
    //         loadScript();
    //       }
    //     } else {
    //       loadScript();
    //     }
    //     function loadScript() {
    //       var script = document.createElement('script');
    //       script.async = true;
    //       script.src = scriptURL;
    //       (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    //       script.onload = ShopifyBuyInit;
    //     }
    //     function ShopifyBuyInit() {
    //       var client = ShopifyBuy.buildClient({
    //         domain: 'david-leonard.myshopify.com',
    //         storefrontAccessToken: 'bd21def0eafdf2c5c8f57e697cf30809',
    //       });
    //       ShopifyBuy.UI.onReady(client).then(function (ui) {
    //         ui.createComponent('collection', {
    //           id: '402640109808',
    //           node: document.getElementById('collection-component-1650657017598'),
    //           moneyFormat: '%24%7B%7Bamount%7D%7D',
    //           options: {
    //     "product": {
    //       "styles": {
    //         "product": {
    //           "@media (min-width: 601px)": {
    //             "max-width": "calc(25% - 20px)",
    //             "margin-left": "20px",
    //             "margin-bottom": "50px",
    //             "width": "calc(25% - 20px)"
    //           },
    //           "img": {
    //             "height": "calc(100% - 15px)",
    //             "position": "absolute",
    //             "left": "0",
    //             "right": "0",
    //             "top": "0"
    //           },
    //           "imgWrapper": {
    //             "padding-top": "calc(75% + 15px)",
    //             "position": "relative",
    //             "height": "0"
    //           }
    //         }
    //       },
    //       "text": {
    //         "button": "Add to cart"
    //       }
    //     },
    //     "productSet": {
    //       "styles": {
    //         "products": {
    //           "@media (min-width: 601px)": {
    //             "margin-left": "-20px"
    //           }
    //         }
    //       }
    //     },
    //     "modalProduct": {
    //       "contents": {
    //         "img": false,
    //         "imgWithCarousel": true,
    //         "button": false,
    //         "buttonWithQuantity": true
    //       },
    //       "styles": {
    //         "product": {
    //           "@media (min-width: 601px)": {
    //             "max-width": "100%",
    //             "margin-left": "0px",
    //             "margin-bottom": "0px"
    //           }
    //         }
    //       },
    //       "text": {
    //         "button": "Add to cart"
    //       }
    //     },
    //     "option": {},
    //     "cart": {
    //       "text": {
    //         "total": "Subtotal",
    //         "button": "Checkout"
    //       }
    //     },
    //     "toggle": {}
    //   },
    //         });
    //       });
    //     }
    //   })();
    $.magnificPopup.open({
        items: {
          src: '#popup', 
          type: 'inline'
        }
    });
    $('#popup a').on('click', function(){ 
        $.magnificPopup.close();
    })

});